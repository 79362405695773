<template>
	<figure id="logo">
		<img v-if="$store.getters.theme.logo && $store.getters.theme.logo != ''" class="img-responsive" alt="Imagem" :src="$store.getters.theme.logo">
		<img v-else class="img-responsive" alt="Logo" :src="Logo">
	</figure>
</template>

<script>
	import store from '@/store'
	import Logo from "@/assets/images/LOGO_PNG_LOGO AMARELA.png"
	export default {
		name: 'Logo',
		data() {
			if(store.getters.theme.logo && store.getters.theme.logo != ''){
				return {
					theme:store.theme
				}
			}else{
				return{
					Logo: Logo
				}
			}
		},
	}
</script>

<style lang="scss">
	#logo{
		justify-content: center;
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		margin: auto;
		max-width: 150px;
		margin-bottom: 30px;
		margin-top: 30px;
		img{
			width: 100%;
		}
	}
</style>