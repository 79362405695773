<template>
	<figure id="background-direita">
		<img v-if="$store.getters.theme.logo_direita && $store.getters.theme.logo_direita != ''" class="img-responsive" alt="Imagem" :src="$store.getters.theme.logo_direita">
		<img v-else class="img-responsive" alt="Imagem" :src="imagem">
	</figure>
</template>

<script>
	import store from '@/store'
	import Imagem from "@/assets/images/LOGO_PNG_LOGO AMARELA.png"
	export default {
		name: 'LoginDireita',
		data() {
			if(store.getters.theme.logo_direita && store.getters.theme.logo_direita != ''){
				return {
					theme:store.theme
				}
			}else{
				return{
					imagem: Imagem
				}
			}
		},
	}
</script>

<style lang="scss">
	#background-direita{
		width: 102%;
		height: 102%;
		justify-content: center;
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		img{
			margin-right: 0;
		}
	}
</style>